<template>
  <div class="chat" :class="{ 'chat-mobile': $vuetify.breakpoint.xsOnly }" v-resize="onResize">
    <MessengerSidebar/>

    <v-sheet
      class="chat__content"
      v-if="
        ($route.query.r && $vuetify.breakpoint.xsOnly) ||
        !$vuetify.breakpoint.xsOnly
      "
      :outlined="loadingRooms"
    >
      <div class="d-flex" v-if="loadingRooms" style="height: 100%; width: 100%">
        <v-progress-circular
          class="ma-auto align-self-center"
          color="primary"
          indeterminate
          size="50" />
      </div>

      <MessengerRoom v-else :key="$route.query.r" />
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MessengerSidebar from "@/components/messenger/MessengerSidebar";
import MessengerRoom from "@/components/messenger/MessengerRoom";
import ChatRoom from "@/store/models/ChatRoom";
import messenger from "@/utils/mixins/messenger";
import user from "@/utils/mixins/user";
import messengerService from "@/services/messengerService";
import ChatMessage from "@/store/models/ChatMessage";
import * as messengerMutationTypes from "@/store/mutation-types/messenger";

export default {
  name: "Messenger",
  mixins: [messenger, user],
  components: {
    MessengerSidebar,
    MessengerRoom,
  },
  computed: {
    ...mapGetters("school", ["school"]),
    loadingRooms() {
      return this.$store.state.entities.chatRooms.loading;
    },
  },
  watch: {
    loadingRooms(state) {
      if (state === false) {
        const query = Object.assign({}, this.$route.query);
        const room = ChatRoom.query().where("uuid", query.r).first();

        if (query.r && room === null) {
          messengerService
            .loadRoom(query.r)
            .then((resp) => {
              const room = resp.data || {};

              ChatRoom.insert({ data: room });
              ChatMessage.insert({ data: room.lastMessage || {} });
            })
            .catch(error => {
              console.log(error);

              delete query.r;
              this.$router.replace({ query }).catch((error) => console.log(error));
            })
        }
      }
    },
    "$route.query.r": function(value) {
      const room = ChatRoom.query().where("uuid", value).first();

      if (room?.createdAt !== null) {
        this.$store.commit(`messenger/${messengerMutationTypes.SET_DRAFT_MESSAGE}`, "");
      }
    }
  },
  mounted() {
    ChatRoom.commit((state) => (state.loading = true));
    messengerService
      .loadRooms({
        pagination: true,
        perPage: ChatRoom.state().pagination.perPage
      })
      .then((resp) => {
        const lastMessages = [];
        const responseData = resp.data || {};
        const rooms = responseData.data || [];

        ChatRoom.commit((state) => {
          state.pagination.set(responseData.pagination || {})
        });

        rooms.forEach((room) => lastMessages.push(room.lastMessage));

        ChatRoom.create({ data: rooms });
        ChatMessage.create({ data: lastMessages.length ? lastMessages : [] });

        this.initRoomsForStudentsTeachers()
      })
      .finally(() => {
        ChatRoom.commit((state) => (state.loading = false));
      });

    this.$store.dispatch("auth/getAuthUser");
  },
  methods: {
    onResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
};
</script>

<style lang="scss">
.chat {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &__content {
    width: inherit;

    .sidebar__panel {
      width: inherit;
    }
  }

  &-mobile {
    .chat__sidebar {
      width: inherit;

      .sidebar__panel {
        width: inherit;
      }
    }

    .room {
      &__messages {
        height: calc(100vh - 181px) !important;
        margin-top: 55px;
      }
    }
  }

  .loader {
    margin-top: 20%;
  }
}
</style>
