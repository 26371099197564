import axios from "axios";
import store from "@/store";
import SnackbarModel from "@/store/models/SnackbarModel";
import laravelEcho from "@/plugins/laravel-echo";
// import * as authMutationTypes from "@/store/mutation-types/auth";
import LocalizationManager from '@/helpers/LocalizationManager'

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data = {}, headers) {
      const accessToken = localStorage.getItem("accessToken");
      const selectedStudentId = localStorage.getItem("parentSelectedStudentId");

      if (accessToken) {
        headers.Authorization = "Bearer " + accessToken;
        headers["X-Socket-Id"] = laravelEcho.socketId();
      }

      const currenUser = store.getters["auth/currentUser"];
      if (currenUser?.type === "elter" && selectedStudentId) {
        headers["X-Student-Id"] = selectedStudentId;
      }

      headers["Accept-Language"] = LocalizationManager.getCurrent()

      return data instanceof FormData ? data : JSON.stringify(data);
    },
  ],
});

api.interceptors.response.use(
  null,
  (error) => {
    if (!error.response) return false;

    const response = error.response || {};
    const responseData = response.data || {};

    if ([400, 405, 500].includes(error.response.status) && store.state.main.errorBottomSheet.status !== "pending") {
      SnackbarModel.insert({
        data: {
          icon: "mdi-exclamation",
          message: responseData.message,
          color: "error",
          timeout: 6000
        }
      });
      if (responseData.message) error.response.data = {};
    }

    // const authState = store.state.auth;
    // const isUnauthorizedUser = authState.authToken && error.response.status === 401;
    // const isUnauthorizedParent = authState.authUser?.type === "elter" && authState.parentSelectedStudentId && error.response.status === 412;

    // store.commit(`auth/${authMutationTypes.SESSION_EXPIRED}`, isUnauthorizedUser)
    // store.commit(`auth/${authMutationTypes.RESTRICTED_ACCESS}`, isUnauthorizedParent)

    return Promise.reject(error);
  }
);

export default api;
