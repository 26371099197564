<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.personalSettings.children.calendarSettings") }}
      </v-card-title>

      <v-spacer />

      <v-btn text color="primary" @click="save">{{ $t("btn.save") }}</v-btn>
    </v-toolbar>

    <v-card-text>
      <SettingsResponsiveRow
        :description="$t('messages.students_will_able_book_app_dur_selected_period')"
        :title="$t('label.appointments_can_booked_up_to_max')"
          :divider-after="false"
      >
        <v-select
            hint=""
            :items="weeks"
            v-model="form.bookingLimitMax"
            item-value="id"
            item-text="label"
            persistent-hint
            outlined
            dense
            single-line
        />
      </SettingsResponsiveRow>

    </v-card-text>
  </div>
</template>

<script>
import user from "@/utils/mixins/user";
import { mapActions, mapGetters } from "vuex";
import teacherService from "@/services/teacherService";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "CalendarSettings",
  components: {SettingsResponsiveRow},
  mixins: [user, responsive],
  data: () => ({
    form: {
      bookingLimitMax: "",
    },
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    weeks() {
      return [
        { id: 0, label: this.$t("label.no_limit") },
        { id: 1, label: `1 ${this.$tc("label.week", 1)}` },
        { id: 2, label: `2 ${this.$tc("label.week", 2)}` },
        { id: 3, label: `3 ${this.$tc("label.week", 3)}` },
        { id: 4, label: `4 ${this.$tc("label.week", 4)}` },
        { id: 5, label: `5 ${this.$tc("label.week", 5)}` },
        { id: 6, label: `6 ${this.$tc("label.week", 6)}` }
      ];
    }
  },
  created() {
    this.form.bookingLimitMax = this.currentUser.bookingLimitMax;
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    save() {
      teacherService
        .updateBookingLimit(this.form)
        .then(() => {
          this.getAuthUser();
          this.$snackbar.show({
            message: this.$t("messages.successfully_sent"),
            color: "success",
            timeout: 2000,
          });
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped></style>
