<template>
  <v-list v-if="rooms.length" class="py-0">
    <div class="text-left px-2" v-if="title && !isSideBarMini">
      <span class="text-caption">{{ title }}</span>
    </div>

    <v-list-item
      class="participant"
      :class="{ 'pb-1': isSideBarMini }"
      v-for="item in rooms"
      :key="item.id"
      :input-value="activeRoom.uuid === item.uuid"
      @click="openDialogRoom(item.conversationPartner)"
    >
      <v-list-item-avatar class="participant__avatar">
        <v-badge
          color="green"
          bottom
          right
          overlap
          dot
          offset-x="10"
          offset-y="10"
          :value="item.online"
        >
          <UserAvatar :path="item.image" :alt="item.fullTitle"/>
        </v-badge>
      </v-list-item-avatar>

      <v-list-item-content>
        <div class="participant__header d-flex justify-space-between">
          <div class="participant__title text-truncate mr-1">
            {{ item.fullTitle }}
          </div>
          <div class="participant__last-message d-flex justify-end text-caption" v-if="item.lastMessage">
            <MessageStatus :message="item.lastMessage" />
          </div>
        </div>

        <div class="d-flex mt-1" style="width: 100%">
          <div
            class="text-caption text-truncate text--secondary mr-2"
            v-if="item.lastMessage"
          >
            <span v-if="item.lastMessage.userId === currentUser.userId">{{ $t("label.you") }}:</span>

            {{ item.lastMessage.message }}
          </div>

          <v-badge
            v-if="!isElter"
            style="height: 8px"
            bottom
            right
            overlap
            inline
            class="ml-auto"
            color="red"
            :value="!!item.unSeenMessagesCount"
            :content="item.unSeenMessagesCount"
          />
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import MessageStatus from "@/components/messenger/MessageStatus";
import messenger from "@/utils/mixins/messenger";

import ChatRoom from "@/store/models/ChatRoom";
import UserAvatar from '@/components/UserAvatar'
import user from '../../utils/mixins/user'

export default {
  name: "MessengerRoomsGroup",
  mixins: [messenger, user],
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    searchText: {
      type: String,
    },
  },
  data: () => ({
    open: false,
  }),
  components: { UserAvatar, MessageStatus },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapState("messenger", ["isSideBarMini"]),
    activeRoom() {
      return ChatRoom.query().where("uuid", this.$route.query.r).first() || {};
    },
    rooms() {
      const currentUserId = this.isElterWithSelectedStudent ?
        this.parentSelectedStudent.userId : this.currentUser.userId;

      const chatRoomQuery = ChatRoom.query().where(
        "conversationPartner",
        (conversationPartner) => {
          let isInCorrectGroup = conversationPartner?.userId !== currentUserId;

          if (this.type) {
            isInCorrectGroup = isInCorrectGroup && conversationPartner?.type === this.type;
          }

          return isInCorrectGroup
        }
      );

      if (!(this.isStudent || this.isElterWithSelectedStudent)) {
        chatRoomQuery.where((room) => room.createdAt !== null);
      }

      return chatRoomQuery
        .orderBy((room) => room.createdAt !== null, "desc")
        .orderBy((room) => room.lastMessage?.createdAt, "desc")
        .get();
    },
  },
};
</script>

<style scoped lang="scss">
.chat {
  .participant {
    &__header {
      max-width: 100%;
    }

    &__avatar {
      overflow: unset;
    }

    &__last-message {
      min-width: 65px;
    }
  }
}
</style>
