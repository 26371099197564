<template>
  <div class="room__header">
    <v-app-bar
      color="white"
      flat
      class="room__bar"
      dense
      height="55"
      style="padding-top: 0 !important; height: 55px !important"
    >
      <v-btn
        icon
        @click="removeRoomFromRouteQuery"
        v-if="$vuetify.breakpoint.xsOnly">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <UserAvatar :path="activeRoom.image" :alt="activeRoom.fullTitle" />

      <v-toolbar-title class="d-flex flex-column">
        <span class="font-weight-bold" style="font-size: 15px">{{ activeRoom.fullTitle }}</span>

        <div v-if="isTyping" class="text-caption">
          <span class="chat__typing-dot" />
          <span class="chat__typing-dot" />
          <span class="chat__typing-dot" />
        </div>

        <span v-else class="text-caption">{{ activeRoom.online ? $t("label.online") : $t("label.offline") }}</span>
      </v-toolbar-title>

      <div class="ml-auto" style="margin-right: -10px">
        <v-btn
          v-if="!$vuetify.breakpoint.xsOnly"
          icon
          @click="removeRoomFromRouteQuery"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <div class="search-bar d-flex" v-if="showSearch">
      <v-icon class="ml-2 mr-1">mdi-arrow-up</v-icon>
      <v-icon class="mr-2 ml-1">mdi-arrow-down</v-icon>
      <v-text-field
        dense
        :label="$t('search') + '...'"
        outlined
        :hide-details="true"
        prepend-inner-icon="mdi-magnify" />
      <v-btn
        v-if="!$vuetify.breakpoint.xsOnly"
        color="grey"
        text
        @click="showSearch = false"
        >{{ $t("btn.close") }}</v-btn
      >
      <v-btn v-else icon dark @click="showSearch = false"
        ><v-icon color="primary">mdi-close</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
import ChatRoom from "@/store/models/ChatRoom";
import messenger from "@/utils/mixins/messenger";
import user from "@/utils/mixins/user";
import UserAvatar from '@/components/UserAvatar'
import { mapGetters } from "vuex";


export default {
  name: "MessengerRoomHeader",
  components: { UserAvatar },
  mixins: [user, messenger],
  data: () => ({
    showSearch: false,
    isTyping: false,
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    activeRoom() {
      return ChatRoom.query()
          .with("conversationPartner")
        .where("uuid", this.$route.query.r)
        .first() || {}
    }
  },
  mounted() {
    this.listenForTyping();
  },
  methods: {
    listenForTyping() {
      if(this.activeRoom.createdAt === null || this.isElter) return

      this.$echo
        .private("messenger-room." + this.activeRoom.uuid)
        .listenForWhisper("typing", (user) => {
          const conversationPartner = this.activeRoom.conversationPartner || {};

          if (
              user.userId === conversationPartner.userId ||
              (user.type === "school-administrator" && this.activeRoom.schoolId)
          ) {
            if (typeof this.isTyping === "boolean") {
              this.isTyping = setTimeout(() => {
                this.isTyping = false;
              }, 2000);
            }
          }
        });
    }
  }
};
</script>

<style scoped lang="scss">
.room__bar {
  @media only screen and (max-width: 640px) {
    position: fixed;
    z-index: 2;
  }

  border-bottom: 1px solid #ccc !important;

  .v-avatar {
    margin-right: 10px;
  }
}

.chat {
  &__typing-dot {
    height: 5px;
    width: 5px;
    border-radius: 100%;
    margin-left: 4px;
    display: inline-block;
    background-color: #5c5e69;
    animation: 1.2s typing-dot ease-in-out infinite;
  }

  &__typing-dot:nth-of-type(2) {
    animation-delay: 0.15s;
  }

  &__typing-dot:nth-of-type(3) {
    animation-delay: 0.25s;
  }

  @keyframes typing-dot {
    15% {
      transform: translateY(-35%);
      opacity: 0.5;
    }
    30% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

.search-bar {
  position: absolute;
  z-index: 2;
  width: 100%;
  padding: 10px;
  background-color: #fff;
}
</style>
