<template>
  <v-card :loading="loading">
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t("label.students_balance") }}</span>
        <span class="text-caption">{{ selectedRange.text }}</span>
      </div>

      <v-menu offset-y left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon small v-bind="attrs" v-on="on"
            ><v-icon>mdi-filter-outline</v-icon></v-btn
          >
        </template>

        <v-list>
          <v-list-item
            @click="filterRange(item)"
            v-for="item in rangeFilter.selectOptions"
            :key="item.value"
            link>
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div class="students__balance d-flex">
        <div class="students__balance-money pa-2">
          <div>
            <div class="d-flex flex-column mb-4">
              <span class="text-caption mb-1">
                <strong>{{ $t("label.payments") }}</strong>
              </span>
              <v-skeleton-loader v-if="loading" type="image" height="32" width="120" />
              <span v-else class="text-h5">{{studentsTotalMoneyAmount | currency}}</span>
            </div>

            <!--            <LineChart :height="100" :data="studentsBalance.total" v-if="studentsBalance.total.length"/>-->
          </div>
        </div>

        <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>

        <div class="students__balance-spent pa-2">
          <div>
            <div class="d-flex flex-column mb-4">
              <span class="text-caption mb-1">
                <strong>{{ $t("label.expenses") }}</strong>
              </span>
              <v-skeleton-loader v-if="loading" type="image" height="32" width="120" />
              <span v-else class="text-h5">{{studentsSpentMoneyAmount | currency}}</span>
            </div>

            <!--            <LineChart :height="110" :data="studentsBalance.spent" v-if="studentsBalance.spent.length"/>-->
          </div>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text>
      <v-card-title class="pa-0">{{ $t('label.total_amount_student_balances') }}</v-card-title>
      <div class="text-caption mb-2">{{ $t('label.total_amount_student_balances_description') }}</div>

      <div class="students__balance d-flex">
        <div class="students__balance-money pa-2">
          <div class="d-flex flex-column mb-4">
            <span class="text-caption mb-1">
              <strong>{{ $t("label.overall_students_balance") }}</strong>
            </span>
            <v-skeleton-loader v-if="loadingOverall" type="image" height="32" width="120" />
            <span v-else class="text-h5">{{ studentsBalance.overallBalance | currency }}</span>
          </div>
        </div>

        <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>

        <div class="students__balance-money pa-2">
          <div class="d-flex flex-column mb-4">
            <span class="text-caption mb-1">
              <strong>{{ $t("label.overall_students_negative_balance") }}</strong>
            </span>
            <v-skeleton-loader v-if="loadingOverall" type="image" height="32" width="120" />
            <span v-else class="text-h5">{{ studentsBalance.overallNegativeBalance | currency }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// import LineChart from '@/components/charts/LineChart'
import schoolService from "@/services/schoolService";
import daysFilter from "@/utils/mixins/daysFilter";

export default {
  name: "BalanceStats",
  components: {
    // LineChart
  },
  mixins: [daysFilter],
  data() {
    return {
      loading: true,
      loadingOverall: false,
      studentsBalance: {
        total: 0,
        spent: 0,
      },
    };
  },
  mounted() {
    this.load(true);
  },
  methods: {
    load(loadOverallBalance = false) {
      this.loading = true;
      if (loadOverallBalance) this.loadingOverall = true;
      schoolService
        .studentsBalance({
          from: this.datesRange.from,
          to: this.datesRange.to,
        })
        .then((response) => {
          this.studentsBalance = response.data || {};
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
          this.loadingOverall = false;
        });
    },
    filterRange(filterItem) {
      this.rangeFilter.selectedValue = filterItem.value;

      // this.$router.push({
      //   query: {
      //     ...this.$route.query,
      //     balanceRange: this.queryValue
      //   }
      // })

      this.load();
    },
  },
  computed: {
    studentsTotalMoneyAmount() {
      return this.studentsBalance.total.reduce(
        (total, item) => total + parseFloat(item.moneyAmount),
        0
      );
    },
    studentsSpentMoneyAmount() {
      return Math.abs(
        this.studentsBalance.spent.reduce(
          (total, item) => total + parseFloat(item.moneyAmount),
          0
        )
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.students__balance {
  &-money,
  &-spent {
    width: 50%;
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    flex-direction: column;

    &-money,
    &-spent {
      width: 100%;
    }
  }
}
</style>
