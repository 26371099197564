import { Model } from "@vuex-orm/core";
import ChatMessage from "@/store/models/ChatMessage";
import { v4 as uuid4 } from "uuid";
import Pagination from '@/helpers/pagination'
import ChatParticipant from "@/store/models/ChatParticipant";

export default class ChatRoom extends Model {
  static entity = "chatRooms";

  static state() {
    return {
      loading: false,
      pagination: new Pagination({ perPage: 50 })
    };
  }

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      uuid: this.uid(() => uuid4()),
      title: this.attr(null),
      type: this.string("one-to-one"),
      schoolId: this.attr(null),
      unSeenMessagesCount: this.number(0),
      conversationPartner: this.attr(null),
      participantsIds: this.attr([]),
      lastMessageId: this.attr(null),
      messages: this.hasMany(ChatMessage, "roomId"),
      createdAt: this.attr(null),
      draftMessage: this.string("")
    };
  }

  get lastMessage() {
    return ChatMessage.find(this.lastMessageId);
  }

  get image() {
    const school = this.$store().state.school || {};
    const currentUser = this.$store().state.auth.authUser;

    if (currentUser.type !== "school-administrator" && this.schoolId) {
      return school.avatar;
    }

    return this.conversationPartner?.avatar;
  }

  get fullTitle() {
    const school = this.$store().state.school || {};
    const currentUser = this.$store().state.auth.authUser;

    if (this.title) return this.title;

    if (currentUser.type !== "school-administrator" && this.schoolId) {
      return school.name;
    }

    return this.conversationPartner?.firstName + " " + this.conversationPartner?.lastName;
  }
  get online() {
    const currentUser = this.$store().state.auth.authUser;
    const onlineParticipantsIds = this.$store().state.messenger.onlineParticipantsIds

    if (currentUser.type !== "school-administrator" && this.schoolId) {
      return ChatParticipant.query()
        .where("type", "school-administrator")
        .get()
        .some((item) => item.online);
    }

    return onlineParticipantsIds.includes(this.conversationPartner?.userId);
  }

  excludeCurrentUser(participantsIds) {
    const currentUser = this.$store().state.auth.authUser;
    return participantsIds.filter((id) => id !== currentUser.userId);
  }
}
